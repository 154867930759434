import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Form,
  Item,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  Popup,
  RequiredRule,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import LogTagRecordService from "./LogTagRecordService";
import { DateBox, FileUploader, NumberBox } from "devextreme-react";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LogDeviceService from "../devicelog/logdevice/LogDeviceService";
import DialogContent from "@mui/material/DialogContent";
import ScrollView from "devextreme-react/scroll-view";
import LogTagRecordDetailService from "./LogTagRecordDetailService";
import notify from "devextreme/ui/notify";
import { t } from "i18next";

const authProvider = AuthProvider();
const logTagRecordService = LogTagRecordService();
const logTagRecordDetailService = LogTagRecordDetailService();
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return logTagRecordService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return logTagRecordService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return logTagRecordService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return logTagRecordService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return logTagRecordService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

const logDeviceService = LogDeviceService();
const deviceDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    return logDeviceService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return logDeviceService.findOne(key).then((response: any) => {
      return response;
    });
  },
});

function LogTagRecords() {
  const [dataGrid, setDataGrid] = useState();
  const [openOrder, setOpenOrder] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [tagDataGrid, setTagDataGrid] = useState();
  const { t } = useTranslation();

  const tagRecordDataSource = new DataSource({
    store: new CustomStore({
      key: "id",
      load(loadOptions: any) {
        loadOptions.filter = ["logFile.id", "=", selectedGroup.id];
        return logTagRecordDetailService.findAll(loadOptions).then(
          (response) => ({
            data: response.items,
            totalCount: response.totalCount,
          }),
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      byKey: (key: any) => {
        return logTagRecordDetailService.findOne(key).then((response) => {
          return response;
        });
      },
      insert: (values: any) => {
        return logTagRecordDetailService.save(values).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
    }),
  });

  const editCSVfile = (d) => {
    return (
      <FileUploader
        selectButtonText={t("{{key, capitalize}}", { key: "select.csv" })}
        labelText={t("drop.csv")}
        accept=".xls"
        uploadMode="useForm"
        multiple={true}
        onValueChanged={(val) => {
          debugger;
          // d.setValue(null);
          d.setValue(val.value);
        }}
      />
    );
  };

  const editDate = (d) => {
    return (
      <DateBox
        defaultValue={new Date()}
        type="date"
        onValueChanged={(val) => {
          debugger;
          d.setValue(null);
          d.setValue(
            val.value.getDate().toString().padStart(2, "0") +
              "/" +
              val.value.getUTCMonth().toString().padStart(2, "0") +
              "/" +
              val.value.getUTCFullYear()
          );
        }}
      />
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("section") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            // allowUpdating={false}
            allowUpdating={false}
            allowAdding={authProvider.isAuth("logFileSave")}
            allowDeleting={false}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "log.upload.popup.title" })}
              showTitle={true}
            />
            <Form>
              {/*<Item dataField="rfKeeperType.id" />*/}
              <Item dataField="encodingDate" />
              <Item dataField="logDevice.id" />
              <Item dataField="csvFile" />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 15, 20, 50, 100]}
            showInfo={true}
          />
          <Column
            caption=""
            dataField="id"
            dataType="number"
            sortOrder="desc"
            width={70}
            allowFiltering={false}
            allowSorting={false}
            allowEditing={false}
            cellRender={(item) => (
              <Button
                type="default"
                icon="bulletlist"
                onClick={() => {
                  setSelectedGroup(item.data);
                  setOpenOrder(true);
                }}
              />
            )}
          />
          <Column
            caption={t("{{key, capitalize}}", { key: "fileName" })}
            dataField="fileName"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "count" })}
            dataField="count"
            dataType="number"
          ></Column>

          <Column
            dataField="csvFile"
            caption={t("{{key, capitalize}}", { key: "csv.file" })}
            visible={false}
            editCellRender={editCSVfile}
            allowSearch={false}
            allowFiltering={false}
          />

          <Column
            dataField="encodingDate"
            caption={t("{{key, capitalize}}", { key: "encodingDate" })}
            visible={true}
            allowFiltering={false}
            dataType="date"
          />
          <Column caption={t("{{key, capitalize}}", { key: "device" })} dataField="logDevice.id">
            <Lookup dataSource={deviceDataSource} displayExpr="name" valueExpr="id" />
            <RequiredRule />
          </Column>
        </DataGrid>
      </SuiBox>
      <Footer />
      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        key={11}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            // setExtraRate(0);
            setOpenOrder(false);
            dataGrid.instance.refresh();
          }}
        >
          {selectedGroup !== undefined && selectedGroup !== null
            ? "'" +
              selectedGroup.fileName +
              "' " +
              t("{{key, capitalize}}", { key: "log.epc.detail" })
            : t("{{key, capitalize}}", { key: "log.epc.detail" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <ScrollView width="100%" height="100%">
            <DataGrid
              dataSource={tagRecordDataSource}
              showBorders={false}
              remoteOperations={false}
              rowAlternationEnabled={true}
              ref={(ref) => {
                setTagDataGrid(ref);
              }}
            >
              <FilterRow visible={false} />
              <ColumnChooser enabled={false} />
              <LoadPanel enabled={true} />
              <Toolbar>
                <Item department="after">
                  <Button
                    icon="refresh"
                    onClick={() => {
                      tagDataGrid.instance.refresh();
                    }}
                  />
                </Item>
                <Item name="addRowButton" />
                <Item name="searchPanel" />
                <Item name="columnChooserButton" />
              </Toolbar>
              <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
              <Editing
                mode="row"
                allowUpdating={false}
                // allowUpdating={authProvider.isAuth("tempeValidationUpdate")}
                // allowAdding={authProvider.isAuth("tempeValidationSave")}
                // allowDeleting={authProvider.isAuth("tempeValidationDelete")}
                allowAdding={false}
                allowDeleting={false}
              ></Editing>
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

              <Column
                caption=""
                dataField="id"
                dataType="number"
                allowEditing={false}
                width={70}
                allowFiltering={false}
                // allowSorting={false}
                cellRender={(item) => (
                  <>
                    {item.data.success === true ? (
                      <Button type="success" icon="check" />
                    ) : (
                      <Button
                        type="danger"
                        icon="remove"
                        onClick={() =>
                          notify({
                            message: item.data.error,
                            type: "error",
                            displayTime: 3000,
                            height: 50,
                            shading: false,
                          })
                        }
                      />
                    )}
                  </>
                )}
              />

              <Column
                caption={t("{{key, capitalize}}", { key: "epc" })}
                dataField="epc"
                allowEditing={false}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "tid" })}
                dataField="tid"
                allowEditing={false}
              ></Column>
            </DataGrid>
          </ScrollView>
        </DialogContent>
      </BootstrapDialog>
    </DashboardLayout>
  );
}

export default LogTagRecords;
