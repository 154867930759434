import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import brandEpc from "assets/images/epcstore_logo.png";
import brand from "assets/images/rapidobox_logo.png";
// Authentication layout components
import CoverLayout from "layouts/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";
import curvedepc from "assets/images/curved-images/epc.png";
import rapidoLoginImage from "assets/images/rapidobox-login.svg";
import epcStoreLoginImage from "assets/images/epc-store-login.png";

import { useFormik } from "formik";
import { LoadPanel } from "devextreme-react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import AuthService from "../AuthService";
import { useTranslation } from "react-i18next";

function LoginPage() {
  const { t } = useTranslation();
  const [showLoadPanel, setShowLoadPanel] = useState(false);
  const navigate = useNavigate();
  const domain = window.location.href;
  let backgroundImage = rapidoLoginImage;
  let logo = brand;
  let loginText = "RapidoBox";
  let loginImageCover = true;
  let navigatePath = "/dashboards/main-dashboard";
  const location = useLocation();
  if (domain.includes("epcstore")) {
    logo = brandEpc;
    backgroundImage = epcStoreLoginImage;
    loginText = "EPC Store";
    loginImageCover = false;
    navigatePath = "/inditex";
  }

  const authService = AuthService();

  useEffect(() => {
    authService.clearAuthData();
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Zorunludur";
    }

    if (!values.password) {
      errors.password = "Zorunludur";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validate,
    onSubmit: (values, actions) => {
      setShowLoadPanel(true);
      actions.setSubmitting(false);
      authService
        .login(values)
        .then((result) => {
          setShowLoadPanel(false);
          const redirectURL =
            new URLSearchParams(location.search).get("redirectURL") ||
            sessionStorage.getItem("redirectAfterLogin") ||
            navigatePath;
          sessionStorage.removeItem("redirectAfterLogin");

          navigate(redirectURL);
        })
        .catch((err) => {
          setShowLoadPanel(false);
        });
    },
  });

  return (
    <CoverLayout image={backgroundImage} loginText={loginText} loginImageCover={loginImageCover}>
      <SuiBox mb={3} display="flex" alignItems="center">
        {logo && <SuiBox component="img" src={logo} alt="RAPIDOBOX Logo" width="10rem" />}
      </SuiBox>
      <SuiBox mb={2} display="flex" alignItems="center">
        <SuiTypography variant="body2" fontWeight="regular" color="text">
          {t("usernamePasswordRequired")}
        </SuiTypography>
      </SuiBox>
      <form onSubmit={formik.handleSubmit}>
        <SuiBox role="form">
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography
                textTransform="capitalize"
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t("username")}
              </SuiTypography>
            </SuiBox>
            <SuiInput
              inputProps={{ type: "text" }}
              placeholder={t("username")}
              onChange={({ currentTarget }) => {
                formik.values.username = currentTarget.value;
              }}
              defaultValue={formik.values.username}
              error={!!formik.errors.username}
            />
          </SuiBox>
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography
                textTransform="capitalize"
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t("password")}
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type="password"
              placeholder={t("password")}
              inputProps={{ minLength: 3 }}
              defaultValue={formik.values.password}
              onChange={({ currentTarget }) => {
                formik.values.password = currentTarget.value;
              }}
              error={!!formik.errors.password}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox mt={5}>
          <SuiButton
            type="submit"
            color="rapidoLogin"
            fullWidth
            sx={{
              backgroundImage: `linear-gradient(123deg, #262E3C 0%, #387D7A 44%, #262E3C 100%)`,
            }}
          >
            {t("enter")}
          </SuiButton>
        </SuiBox>
      </form>

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={showLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={true}
      />
    </CoverLayout>
  );
}

export default LoginPage;
