import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActions, CardHeader, Icon } from "@mui/material";
import SuiButton from "../../../../../../components/SuiButton";
import { useTranslation } from "react-i18next";
import SuiBox from "../../../../../../components/SuiBox";
import Avatar from "@mui/material/Avatar";

function SelectDevicePage({ data, initWebsocket, onSelectDevice }) {
  const { t } = useTranslation();
  return (
    <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
      <Grid container mt={2} spacing={0} bgcolor="transparent">
        {data &&
          data.map((val) => {
            return (
              <Grid
                sx={{
                  boxShadow: val.deviceStatus === "idle" ? "" : "0 1px 3px 3px red",
                  marginTop: "5px",
                }}
                component={Card}
                item
                key={val.id}
                ml={1}
                mr={2}
                mb={1}
                xs={12}
                sm={12}
                md={4}
                lg={3}
              >
                <Card
                  sx={{
                    width: "100%",
                    bgcolor: "#e1e1e1",
                    cursor: val.deviceStatus === "idle" ? "pointer" : null,
                  }}
                  onClick={() => {
                    if (val.deviceStatus === "idle") {
                      onSelectDevice(val);
                      initWebsocket(val.code);
                    }
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: "#082342" }} aria-label="recipe">
                        {val.name.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    title={<b style={{ fontSize: "20px" }}>{val.name.toUpperCase()}</b>}
                  />
                  {val.contentId === undefined ? (
                    <CardMedia
                      sx={{ padding: 2 }}
                      width="100%"
                      component="img"
                      image="/images/inspection/Rapidobox.png"
                      alt="start"
                    />
                  ) : (
                    <CardMedia
                      sx={{ padding: 2 }}
                      component="img"
                      width="100%"
                      image={
                        process.env.REACT_APP_API_URL +
                        "binaryContents/getPublicImage/" +
                        val.contentId
                      }
                      alt="start"
                    />
                  )}
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textAlign: "center", fontWeight: "bold", marginTop: "15px" }}
                    >
                      {val.department.name}
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {val.deviceStatus !== "idle" ? (
                      <>
                        <SuiButton
                          onClick={() => {
                            onSelectDevice(val);
                            initWebsocket(val.code);
                          }}
                          sx={{ marginRight: "auto" }}
                          variant="gradient"
                          color="error"
                          iconOnly
                          circular
                        >
                          <Icon>play_arrow</Icon>
                        </SuiButton>
                      </>
                    ) : null}
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: val.deviceStatus === "idle" ? "green" : "red",
                      }}
                      component="div"
                    >
                      {t("{{key, capitalize}}", { key: val.deviceStatus })}
                    </Typography>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </SuiBox>
  );
}

export default SelectDevicePage;
