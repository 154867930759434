import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  Form,
  Item,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  Popup,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  Toolbar,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import RfKeeperOrderGroupService from "./RfKeeperOrderGroupService";
import { FileUploader, NumberBox } from "devextreme-react";
import DialogContent from "@mui/material/DialogContent";
import ScrollView from "devextreme-react/scroll-view";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RfKeeperOrderService from "./RfKeeperOrderService";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import RfKeeperTagService from "./RfKeeperTagService";
import RfKeeperTypeService from "./type/RfKeeperTypeService";

const authProvider = AuthProvider();
const rfKeeperOrderGroupService = RfKeeperOrderGroupService();
const rfKeeperOrderService = RfKeeperOrderService();
const rfKeeperTagService = RfKeeperTagService();
const rfKeeperTypeService = RfKeeperTypeService();
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return rfKeeperOrderGroupService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return rfKeeperOrderGroupService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return rfKeeperOrderGroupService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return rfKeeperOrderGroupService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return rfKeeperOrderGroupService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function RfKeeperOrderGroupPage() {
  const [dataGrid, setDataGrid] = useState();
  const [openOrder, setOpenOrder] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [orderDataGrid, setOrderDataGrid] = useState();
  const [openEpcRecord, setOpenEpcRecord] = useState(false);
  const [openEpcJsonDetail, setOpenEpcJsonDetail] = useState(false);
  const [jsonData, setJsonData] = useState(JSON.parse("{}"));
  const [selectedOrder, setSelectedOrder] = useState();
  const { t } = useTranslation();

  const typeDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return rfKeeperTypeService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return rfKeeperTypeService.findOne(key).then((response: any) => {
        return response;
      });
    },
  });

  const epcTags = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      loadOptions.filter = ["rfKeeperOrder.id", "=", selectedOrder.id];
      return rfKeeperTagService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return rfKeeperTagService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  function onChangeExtraRate(e) {
    if (e != null && e !== "") {
      orderDataGrid.instance.beginCustomLoading();
      return rfKeeperOrderService.changeExtraRate(selectedGroup, e).then(
        () => {
          orderDataGrid.instance.endCustomLoading();
          orderDataGrid.instance.refresh();
        },
        (err) => {
          orderDataGrid.instance.endCustomLoading();
          orderDataGrid.instance.refresh();
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    }
  }

  const orderRecordDataSource = new DataSource({
    store: new CustomStore({
      key: "id",
      load(loadOptions: any) {
        loadOptions.filter = ["rfKeeperOrderGroup.id", "=", selectedGroup.id];
        return rfKeeperOrderService.findAll(loadOptions).then(
          (response) => ({
            data: response.items,
            totalCount: response.totalCount,
          }),
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      byKey: (key: any) => {
        return rfKeeperOrderService.findOne(key).then((response) => {
          return response;
        });
      },
      insert: (values: any) => {
        return rfKeeperOrderService.save(values).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      update: (key: any, values: any) => {
        values.id = key;
        return rfKeeperOrderService.update(values).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      remove: (key: any) => {
        return rfKeeperOrderService.deleteOne(key).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
    }),
  });

  const editCSVfile = (d) => {
    return (
      <FileUploader
        selectButtonText={t("{{key, capitalize}}", { key: "select.csv" })}
        labelText={t("drop.csv")}
        accept=".csv"
        uploadMode="useForm"
        onValueChanged={(val) => {
          debugger;
          d.setValue(null);
          d.setValue(val.value[0]);
        }}
      />
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("section") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            // allowUpdating={false}
            allowUpdating={false}
            allowAdding={authProvider.isAuth("rfKeeperOrderGroupSave")}
            allowDeleting={authProvider.isAuth("rfKeeperOrderGroupDelete")}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "rfkeeper.popup.title" })}
              showTitle={true}
            />
            <Form>
              <Item dataField="rfKeeperType.id" />
              <Item dataField="csvFile" />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 15, 20, 50, 100]}
            showInfo={true}
          />
          <Column
            caption=""
            dataField="id"
            dataType="number"
            sortOrder="desc"
            width={70}
            allowFiltering={false}
            allowSorting={false}
            allowEditing={false}
            cellRender={(item) => (
              <Button
                type="default"
                icon="bulletlist"
                onClick={() => {
                  setSelectedGroup(item.data);
                  setOpenOrder(true);
                }}
              />
            )}
          />
          <Column
            caption={t("{{key, capitalize}}", { key: "fileName" })}
            dataField="fileName"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "count" })}
            dataField="count"
            dataType="string"
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "rfkeeper.type.name" })}
            dataField="rfKeeperType.id"
          >
            <Lookup dataSource={typeDataSource} displayExpr="indexName" valueExpr="id" />
            <RequiredRule />
          </Column>

          <Column
            dataField="csvFile"
            caption={t("{{key, capitalize}}", { key: "csv.file" })}
            visible={false}
            editCellRender={editCSVfile}
            allowSearch={false}
            allowFiltering={false}
          />
        </DataGrid>
      </SuiBox>
      <Footer />
      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        key={11}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            // setExtraRate(0);
            setOpenOrder(false);
            dataGrid.instance.refresh();
          }}
        >
          {selectedGroup !== undefined && selectedGroup !== null
            ? "'" +
              selectedGroup.fileName +
              "' " +
              t("{{key, capitalize}}", { key: "rfkeeper.order.content" })
            : t("{{key, capitalize}}", { key: "rfkeeper.order.content" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <ScrollView width="100%" height="100%">
            <DataGrid
              dataSource={orderRecordDataSource}
              showBorders={false}
              remoteOperations={false}
              rowAlternationEnabled={true}
              ref={(ref) => {
                setOrderDataGrid(ref);
              }}
            >
              <FilterRow visible={false} />
              <ColumnChooser enabled={false} />
              <LoadPanel enabled={true} />
              <Toolbar>
                <Item department="after">
                  <Button
                    icon="refresh"
                    onClick={() => {
                      orderDataGrid.instance.refresh();
                    }}
                  />
                </Item>
                <Item location="after">
                  <Button
                    icon="save"
                    onClick={() => {
                      orderDataGrid.instance.beginCustomLoading();
                      return rfKeeperOrderService.createAllEPC(selectedGroup).then(
                        () => {
                          orderDataGrid.instance.endCustomLoading();
                          orderDataGrid.instance.refresh();
                        },
                        (err) => {
                          orderDataGrid.instance.endCustomLoading();
                          orderDataGrid.instance.refresh();
                          // return err.json().then((r) => {
                          //   throw r.errorMessage ? r.errorMessage : r.warningMessage;
                          // });
                        }
                      );
                    }}
                  />
                </Item>
                <Item location="after">
                  <Button
                    icon="download"
                    onClick={() => {
                      orderDataGrid.instance.beginCustomLoading();
                      return rfKeeperOrderService.downloadAllEpc(selectedGroup.id).then(
                        (res) => {
                          var blob2 = new Blob([res], { type: "text/plain;charset=utf-8" });
                          // eslint-disable-next-line no-undef
                          saveAs(blob2, "'" + selectedGroup.fileName + "'.zip");
                          orderDataGrid.instance.endCustomLoading();
                          orderDataGrid.instance.refresh();
                        },
                        (err) => {
                          orderDataGrid.instance.endCustomLoading();
                          orderDataGrid.instance.refresh();
                          return err.json().then((r) => {
                            throw r.errorMessage ? r.errorMessage : r.warningMessage;
                          });
                        }
                      );
                    }}
                  />
                </Item>
                <Item location="after">
                  <Button
                    icon="exportxlsx"
                    onClick={() => {
                      orderDataGrid.instance.beginCustomLoading();
                      return rfKeeperOrderService.downloadAllEpcExcel(selectedGroup.id).then(
                        (res) => {
                          var blob2 = new Blob([res], { type: "text/plain;charset=utf-8" });
                          debugger;
                          // eslint-disable-next-line no-undef
                          saveAs(blob2, selectedGroup.fileName.replace(".csv", ".xlsx"));
                          orderDataGrid.instance.endCustomLoading();
                          orderDataGrid.instance.refresh();
                        },
                        (err) => {
                          orderDataGrid.instance.endCustomLoading();
                          orderDataGrid.instance.refresh();
                          return err.json().then((r) => {
                            throw r.errorMessage ? r.errorMessage : r.warningMessage;
                          });
                        }
                      );
                    }}
                  />
                </Item>
                <Item name="addRowButton" />
                <Item name="searchPanel" />
                <Item name="columnChooserButton" />
                <Item>
                  <div style={{ display: "flex" }}>
                    <NumberBox
                      label={t("{{key, capitalize}}", { key: "extraRate" })}
                      onValueChange={onChangeExtraRate}
                    />
                  </div>
                </Item>
              </Toolbar>
              <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
              <Editing
                mode="row"
                allowUpdating={authProvider.isAuth("rfKeeperOrderUpdate")}
                allowAdding={false}
                allowDeleting={false}
              ></Editing>
              <Paging defaultPageSize={5} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                showInfo={true}
              />
              <Column
                caption=""
                dataField="id"
                dataType="number"
                sortOrder="desc"
                allowEditing={false}
                width={110}
                allowFiltering={false}
                allowSorting={false}
                cellRender={(item) => (
                  <>
                    <Button
                      type="default"
                      icon="find"
                      onClick={async () => {
                        await new Promise((resolve) => setTimeout(resolve, 100)); // 3 sec
                        // setSelectedOrder(item.data);
                        setJsonData(JSON.parse(item.data.otherData ? item.data.otherData : "{}"));
                        setOpenEpcJsonDetail(true);
                      }}
                      style={{ marginRight: "5px" }}
                    />
                    {item.data.status === 0 ? (
                      <Button
                        icon="add"
                        onClick={() => {
                          orderDataGrid.instance.beginCustomLoading();
                          return rfKeeperOrderService.saveAll(item.data.id).then(
                            () => {
                              orderDataGrid.instance.endCustomLoading();
                              orderDataGrid.instance.refresh();
                            },
                            (err) => {
                              return err.json().then((r) => {
                                orderDataGrid.instance.endCustomLoading();
                                // throw r.errorMessage ? r.errorMessage : r.warningMessage;
                              });
                            }
                          );
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {item.data.status === 1 ? (
                      <Button
                        type="default"
                        icon="clock"
                        onClick={() => orderDataGrid.instance.refresh()}
                      />
                    ) : (
                      <></>
                    )}
                    {item.data.status === 2 ? (
                      <Button
                        type="success"
                        icon="check"
                        onClick={async () => {
                          await new Promise((resolve) => setTimeout(resolve, 100)); // 3 sec
                          setSelectedOrder(item.data);
                          setOpenEpcRecord(true);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              />
              <Column
                caption={t("{{key, capitalize}}", { key: "po" })}
                dataField="po"
                allowEditing={false}
                width={120}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "barcode" })}
                dataField="upc"
                allowEditing={false}
                width={160}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "colorCode" })}
                dataField="color"
                allowEditing={false}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "sizeCode" })}
                dataField="size"
                allowEditing={false}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "department" })}
                dataField="department"
                allowEditing={false}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "quantity" })}
                dataField="quantity"
                dataType="number"
                allowEditing={false}
                width={80}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "extraRate" })}
                dataField="extraRate"
                dataType="number"
                width={150}
              >
                <RequiredRule />
              </Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "quantityFinal" })}
                dataField="quantityFinal"
                dataType="number"
                allowEditing={false}
              >
                <RequiredRule />
              </Column>
            </DataGrid>
          </ScrollView>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setOpenEPCRecord(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openEpcRecord}
        key={12}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenEpcRecord(false);
          }}
        >
          {selectedGroup !== undefined && selectedGroup !== null
            ? selectedGroup.fileName + t("{{key, capitalize}}", { key: "castro.epc.detail.title" })
            : t("{{key, capitalize}}", { key: "castro.epc.detail.title" })}
          <br />
          <span style={{ fontSize: "smaller" }}>
            {selectedOrder !== undefined && selectedOrder !== null
              ? (selectedOrder.po !== undefined ? selectedOrder.po : "") +
                " - " +
                (selectedOrder.upc !== undefined ? selectedOrder.upc : "") +
                " " +
                t("{{key, capitalize}}", { key: "epc.detail.title" })
              : t("{{key, capitalize}}", { key: "epc.detail.title" })}
          </span>
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <DataGrid
            dataSource={epcTags}
            showBorders={false}
            remoteOperations={false}
            rowAlternationEnabled={false}
            // onExporting={onExporting}
          >
            <LoadPanel enabled={true} />
            <Toolbar>
              {/*<Item name="exportButton" />*/}
              <Item location="after">
                <Button
                  icon="download"
                  onClick={() => {
                    orderDataGrid.instance.beginCustomLoading();
                    return rfKeeperTagService.downloadAllEpc(selectedOrder.id).then(
                      (res) => {
                        var blob2 = new Blob([res], { type: "text/plain;charset=utf-8" });
                        // eslint-disable-next-line no-undef
                        saveAs(
                          blob2,
                          (selectedOrder.department !== undefined
                            ? selectedOrder.department + "_"
                            : "") +
                            (selectedOrder.po !== undefined ? selectedOrder.po + "_" : "") +
                            (selectedOrder.upc !== undefined ? selectedOrder.upc + "_" : "") +
                            (selectedOrder.color !== undefined ? selectedOrder.color + "_" : "") +
                            (selectedOrder.size !== undefined ? selectedOrder.size : "") +
                            ".txt"
                        );
                        orderDataGrid.instance.endCustomLoading();
                        orderDataGrid.instance.refresh();
                      },
                      (err) => {
                        orderDataGrid.instance.endCustomLoading();
                        orderDataGrid.instance.refresh();
                        return err.json().then((r) => {
                          // throw r.errorMessage ? r.errorMessage : r.warningMessage;
                        });
                      }
                    );
                  }}
                />
              </Item>

              <Item name="columnChooserButton" />
            </Toolbar>
            <Export enabled={true} formats={["csv"]} allowExportSelectedData={true} />

            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15, 20, 50, 100]}
              showInfo={true}
            />
            <Selection mode="multiple" />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Column
              caption={t("{{key, capitalize}}", { key: "epc" })}
              width={400}
              dataField="epc"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "accessPasswordHex" })}
              dataField="accessPassword"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "kill.password" })}
              dataField="killPassword"
            ></Column>
          </DataGrid>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setOpenEPCRecord(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openEpcJsonDetail}
        key={13}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenEpcJsonDetail(false);
          }}
        >
          {selectedGroup !== undefined && selectedGroup !== null
            ? selectedGroup.fileName + t("{{key, capitalize}}", { key: "castro.epc.detail.title" })
            : t("{{key, capitalize}}", { key: "castro.epc.detail.title" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          {Object.entries(jsonData).map((prop) => {
            return <p>{prop[0] + " : " + prop[1]}</p>;
          })}
        </DialogContent>
      </BootstrapDialog>
    </DashboardLayout>
  );
}

export default RfKeeperOrderGroupPage;
