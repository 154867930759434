import DashboardLayout from "../../../layouts/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import SuiBox from "../../../components/SuiBox";
import SuiInput from "../../../components/SuiInput";
import SuiButton from "../../../components/SuiButton";
import TempeEpcResultService from "../tempetagrecord/TempeEpcResultService";
import notify from "devextreme/ui/notify";
import { createTheme } from "@mui/material/styles";
import HtmlEditor, { TableContextMenu, TableResizing } from "devextreme-react/html-editor";

const tempeEpcResultService = TempeEpcResultService();

function TempeEpcDecodePage() {
  const { t } = useTranslation();
  const [epcData, setEpcData] = useState(null);
  const [epc, setEpc] = useState("");

  const propertyCheck = (data, key) => {
    return data.hasOwnProperty(key) ? data[key] : "-";
  };

  const createTextToPrint = (input) => {
    const displayObject = {
      version: propertyCheck(input, "version"),
      brand: propertyCheck(input, "brand"),
      section: propertyCheck(input, "section"),
      productType: propertyCheck(input, "productType"),
      activeTag: propertyCheck(input, "activeTag"),
      encodeCheck: propertyCheck(input, "encodeCheck"),
      inventoryTag: propertyCheck(input, "inventoryTag"),
      supplierId: propertyCheck(input, "supplierId"),
      free: propertyCheck(input, "free"),
      sizeCode: propertyCheck(input, "sizeCode"),
      color: propertyCheck(input, "color"),
      quality: propertyCheck(input, "quality"),
      model: propertyCheck(input, "model"),
      productCompositionId: propertyCheck(input, "productCompositionId"),
      tagType: propertyCheck(input, "tagType"),
      tagSubType: propertyCheck(input, "tagSubType"),
      serialNumber: propertyCheck(input, "serialNumber"),
      barCode: propertyCheck(input, "barCode"),
      eas: propertyCheck(input, "eas"),
      accessPasswordHex: propertyCheck(input, "accessPasswordHex"),
      killPasswordHex: propertyCheck(input, "killPasswordHex"),
      userMemoryHex: propertyCheck(input, "userMemoryHex"),
      redundancyTag: propertyCheck(input, "redundancyTag"),
    };
    const createObjectDisplaySchemeToShowAsTable = (object) => {
      return (
        <SuiBox
          color="black"
          bgColor="#C0C0C0"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          display="grid"
          p={2}
        >
          <TableContextMenu enabled={false} />
          <TableResizing enabled={false} />
          <table>
            <thead>
              <tr style={{ backgroundColor: "#212529" }}>
                <th style={{ textAlign: "center", color: "white" }}>
                  {t("Feature")}
                </th>
                <th style={{ textAlign: "center", color: "white" }}>
                  {t("Value")}
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(object).map((prop, iter) => {
                return (
                  <tr
                    style={{
                      textAlign: "center",
                      backgroundColor: iter % 2 === 0 ? "#e9ecef" : "white",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "center"
                      }}
                    >
                      {t("{{key, capitalize}}", { key: prop[0] })}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p>{prop[1]}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SuiBox>
      );
    };

    return createObjectDisplaySchemeToShowAsTable(displayObject);
  };
  const getDecodedEpc = (epcHex) => {
    console.log("Decode Epc request is gotten");
    if (epcHex === "") {
      notify({
        message: t("Boş veri girildi"),
        type: "error",
        displayTime: 3000,
        height: 50,
        shading: false,
      });
      setEpcData(null);
      return;
    }
    if (epcData) {
      setEpcData(null);
    }
    return tempeEpcResultService.epcDecode(epcHex).then(
      (response) => setEpcData(response),
      (err) => {
        setEpcData(null);
        // return err.json().then((r) => {
        //     throw r.errorMessage ? r.errorMessage : r.warningMessage;
        // });
      }
    );
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getDecodedEpc(epc);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("section") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox sx={{ display: "flex" }} padding={3}>
        <SuiInput
          value={epc}
          placeholder={t("{{key, capitalize}}", { key: "type epc here" })}
          sx={{ marginRight: "5px" }}
          onChange={(e) => {
            setEpc(e.target.value);
          }}
          onKeyDown={(e) => {
            handleKeyPress(e);
          }}
        />
        <SuiButton
          variant="contained"
          color="dark"
          size="medium"
          p={25}
          sx={{
            width: "15%",
            background: "#344767",
            color: "#F8F8F8",
            "&:hover": {
              background: "#344767",
              color: "#F8F8F8",
            },
          }}
          onClick={(event) => {
            getDecodedEpc(epc);
          }}
        >
          {t("{{key, capitalize}}", { key: "decode" })}
        </SuiButton>
      </SuiBox>

      {epcData && (
        <div style={{ paddingRight: "24px", paddingLeft: "24px" }} className="font-weight-normal">
          {createTextToPrint(epcData)}{" "}
        </div>
      )}
    </DashboardLayout>
  );
}

export default TempeEpcDecodePage;
